import * as React from "react";
import { Scrambler } from "react-text-scrambler";
import styled from "styled-components";

interface TextCyclerProps {
  strings?: string[];
  changeFrom: string;
  text: string;
  color: string;
}
const TextCycler = (props: TextCyclerProps) => {
  return (
    <StyledCyclerContainer color={props.color}>
      <Scrambler
        typewriter
        renderIn={2000}
        changeFrom={props.changeFrom}
        text={props.text}
      />
    </StyledCyclerContainer>
  );
};

export { TextCycler };

interface ColorProp {
  color: string;
}

const StyledCyclerContainer = styled.text<ColorProp>`
  color: ${props => props.color};
  transition: color 2s cubic-bezier(1, 0, 0.43, 0.56);
`;
