/* eslint-disable operator-linebreak */
import React, { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import BurgerIcon from "./BurgerIcon";
import { Wrapper } from "./Wrapper";
import theme from "../../util/theme";
import { mobileSize } from "../../util/variables";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { connectAccountToSubscription, getCurrentUser } from "../../services/firebase";
import { AppButton } from "./AppButton";
import { AppLogo } from "./AppLogo";
import { track_nav_clicked } from "../../services/mixpanel/mixpanel";
import { TopBanner } from "./TopBanner";

interface NavbarProps {
  initiallyWhite?: boolean;
  showBanner?: boolean;
}

const NavBar: FunctionComponent<NavbarProps> = ({ initiallyWhite, showBanner = false }) => {
  const [defaultState, setDefaultState] = useState(false);
  const [burgerNav, setBurgerNav] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollValue < -50) {
      setToChangedState();
    } else {
      setToDefaultState();
    }
  }, [scrollValue]);

  const handleScroll = () => {
    setScrollValue(document.body.getBoundingClientRect().top);
  };

  const setToDefaultState = () => {
    setDefaultState(true);
  };

  const setToChangedState = () => {
    setDefaultState(false);
  };

  const showBurgerNav = () => {
    setBurgerNav(!burgerNav);
  };

  const handleLoginClick = () => {
    track_nav_clicked("Login");
    const currentUser = getCurrentUser();
    showBurgerNav();
    if (currentUser) {
      navigate("/user/profile");
    } else {
      navigate("/user/login");
    }
  };

  const buttonName = "Log In";
  return (
    <NavBarContainer defaultState={defaultState} initiallyWhite={initiallyWhite}>
      {showBanner && (
        <TopBanner fixed={false}>
          <BannerText>
            🏆 Winner of Google’s <b>App of the Year!</b> <MobileBr />Celebrate with{" "}
            <Link to="/onboarding/questions">20% off.</Link>
          </BannerText>
        </TopBanner>
      )}
      <NavBarWrapper defaultState={defaultState}>
        <Wrapper>
          <FlexDiv>
            <NavBarIconContainer>
              <Link to="/" onClick={() => track_nav_clicked("Logo")}>
                <AppLogo alt="Imprint logo / homepage" />
              </Link>
            </NavBarIconContainer>
            <MobileBurger>
              <BurgerIcon showNav={showBurgerNav} showNavState={burgerNav} />
            </MobileBurger>
            <MobileIcon onClick={showBurgerNav}>
              <Link to="/" onClick={() => track_nav_clicked("Logo")}>
                <AppLogo size="large" alt="Imprint logo / homepage" />
              </Link>
            </MobileIcon>
            <SideNavDiv showNav={burgerNav}>
              <NavigationContainer>
                <StyledLink to="/about/" onClick={() => track_nav_clicked("About")}>
                  About
                </StyledLink>
                {/* <StyledLink to="/why-trust-imprint/">Why Imprint</StyledLink> */}
                <OutsideLink
                  href="https://imprint.applytojob.com/"
                  onClick={() => track_nav_clicked("Careers")}
                >
                  Careers
                </OutsideLink>
                <ContactAnchor
                  href="mailto:info@imprintapp.com"
                  onClick={() => track_nav_clicked("ContactUs")}
                >
                  Contact Us
                </ContactAnchor>
                <StyledLink to="/faq/" onClick={() => track_nav_clicked("FAQ")}>
                  FAQ
                </StyledLink>
                <StyledButton onClick={handleLoginClick}>{buttonName}</StyledButton>
              </NavigationContainer>
            </SideNavDiv>
            <NavBarOptions>
              <NavList>
                <NavListItem>
                  <StyledLink to="/about/" onClick={() => track_nav_clicked("About")}>
                    About
                  </StyledLink>
                </NavListItem>
                {/* <NavListItem>
                  <StyledLink to="/why-trust-imprint/">Why Imprint</StyledLink>
                </NavListItem> */}
                <NavListItem>
                  <OutsideLink
                    href="https://imprint.applytojob.com/"
                    onClick={() => track_nav_clicked("Careers")}
                  >
                    Careers
                  </OutsideLink>
                </NavListItem>
                <NavListItem>
                  <ContactAnchor
                    href="mailto:info@imprintapp.com"
                    onClick={() => track_nav_clicked("ContactUs")}
                  >
                    Contact Us
                  </ContactAnchor>
                </NavListItem>
                <NavListItem>
                  <StyledLink to="/faq/" onClick={() => track_nav_clicked("FAQ")}>
                    FAQ
                  </StyledLink>
                </NavListItem>
                <NavListItem>
                  <StyledButton onClick={handleLoginClick}>{buttonName}</StyledButton>
                </NavListItem>
                {/* <button
                  type="button"
                  onClick={() => {
                    const uid = "MQBrDwnZsufxP4MLdxzMOh6bPNA3";
                    const customer_id = "cus_OZN15nHczEas44";
                    const fetch = "sub_1NmEHeLvyjRKGUTXYIfCpozp";
                    connectAccountToSubscription({
                      uid,
                      customer_id,
                      fetch_token: fetch,
                    });
                  }}
                >
                  CONNNECT
                </button> */}
              </NavList>
            </NavBarOptions>
            <HideOnMobileDiv>
              {/* <OutboundLink href="https://imprintapp.onelink.me/H4lb/tsu2u9od"> */}
              <GetImprintButton
                initiallyWhite={initiallyWhite}
                defaultState={defaultState}
                onClick={() => {
                  track_nav_clicked("GetImprint");
                  localStorage.setItem("flow", "homepage");
                  navigate("/welcome?from=homepage");
                }}
              >
                Get Imprint
              </GetImprintButton>
              {/* </OutboundLink> */}
            </HideOnMobileDiv>
          </FlexDiv>
        </Wrapper>
      </NavBarWrapper>
    </NavBarContainer>
  );
};

const HideOnMobileDiv = styled.div`
  @media ${mobileSize} {
    display: none;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const BannerText = styled.p`
  margin: 0;
  line-height: 1.25;
  @media ${mobileSize} {
    font-size: 20px;
  }
`;

const MobileBr = styled.br`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;

interface ContainerState {
  defaultState: boolean;
  initiallyWhite?: boolean;
}

const GetImprintButton = styled(AppButton as any)<ContainerState>`
  visibility: ${props => (props.initiallyWhite ? "auto" : props.defaultState ? "hidden" : "auto")};
  opacity: ${props => (props.initiallyWhite ? 1 : props.defaultState ? 0 : 1)};
  font-size: 17px;
  height: 52px;
  width: 167px;
  transition: visibility 0s linear ${props => (props.defaultState ? "500ms" : "0ms")}, opacity 500ms;
`;

const NavBarContainer = styled.div<ContainerState>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  /* height: ${props => (props.defaultState ? "140px" : "92px")}; */
  background-color: ${props =>
    props.initiallyWhite
      ? theme.WHITE_COLOR
      : props.defaultState
      ? "transparent"
      : theme.WHITE_COLOR};
  transition: background-color 500ms, height 500ms, padding-top 0.25s, padding-bottom 0.25s,
    top 0.25s;
  @media ${mobileSize} {
    /* height: 55px; */
    background-color: ${theme.WHITE_COLOR};
  }
`;

const NavBarWrapper = styled.div<ContainerState>`
  width: 100%;
  position: relative;
  max-width: 1600px;
  padding-top: ${props => (props.defaultState ? 44 : 15)}px;
  padding-bottom: ${props => (props.defaultState ? 44 : 15)}px;
  transition: padding 0.25s ease-in-out;
  @media ${mobileSize} {
    padding-top: ${props => (props.defaultState ? 32 : 15)}px;
    padding-bottom: ${props => (props.defaultState ? 32 : 15)}px;
  }
`;

const NavBarIconContainer = styled.div`
  min-width: max-content;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileIcon = styled.div`
  display: none;
  @media ${mobileSize} {
    position: relative;
    display: flex;
    justify-content: left;
    z-index: 9;
    width: 100%;
    text-align: center;
  }
`;

const MobileBurger = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
    position: absolute;
    right: 30px;
    z-index: 9;
    height: 40px;
    width: 40px;
  }
`;

const NavBarOptions = styled.div`
  width: calc(75% - 53px);
  float: left;
  margin-left: 30px;
  margin-top: 2px;
  text-align: right;
`;

const NavList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  width: fit-content;
  height: 100%;
  @media ${mobileSize} {
    display: none;
  }
`;

interface InvertProp {
  invert?: boolean;
}

const NavListItem = styled.div<InvertProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 25px;
  position: relative;
  overflow: visible;
  height: 100%;
  color: ${theme.BLACK_COLOR};
  .active.active {
    &:before {
      content: ".";
      font-size: 30px;
      display: inline-block;
      position: absolute;
      bottom: -16px;
      left: 0;
      text-align: center;
      width: 100%;
      color: ${theme.PRIMARY_COLOR};
    }
  }
`;
interface LinkProps {
  invert?: boolean;
  makeblue?: boolean;
}

const StyledLink = styled(Link)<LinkProps>`
  font-family: ${theme.SECONDARY_FONT};
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  color: ${props => (props.makeblue ? theme.PRIMARY_COLOR : theme.PRIMARY_COLOR)};
  text-decoration: none;
  text-transform: uppercase;
  :hover {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: none;
  }
  @media ${mobileSize} {
    margin-bottom: 20px;
  }
`;

const StyledButton = styled.div<LinkProps>`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0;
  color: ${props => (props.makeblue ? theme.PRIMARY_COLOR : theme.PRIMARY_COLOR)};
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  :hover {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: none;
  }
  @media ${mobileSize} {
    margin-bottom: 20px;
  }
`;

const OutsideLink = styled(OutboundLink)<LinkProps>`
  font-family: ${theme.SECONDARY_FONT};
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0;
  color: ${props => (props.makeblue ? theme.PRIMARY_COLOR : theme.PRIMARY_COLOR)};
  text-decoration: none;
  text-transform: uppercase;
  :hover {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: none;
  }
  @media ${mobileSize} {
    margin-bottom: 20px;
  }
`;

const ContactAnchor = styled.a<LinkProps>`
  font-family: ${theme.SECONDARY_FONT};
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0;
  color: ${props => (props.makeblue ? theme.PRIMARY_COLOR : theme.PRIMARY_COLOR)};
  text-decoration: none;
  text-transform: uppercase;
  :hover {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: none;
  }
  @media ${mobileSize} {
    margin-bottom: 20px;
  }
`;

interface SideNavProps {
  showNav: boolean;
}

const SideNavDiv = styled.div<SideNavProps>`
  display: none;
  @media ${mobileSize} {
    display: ${props => (props.showNav ? "flex" : "none")};
    height: auto;
    max-height: ${props => (props.showNav ? "500px" : "0px")};
    width: 101%;
    opacity: ${props => (props.showNav ? 1 : 0)};
    position: absolute;
    z-index: ${props => (props.showNav ? 7 : -1)};
    left: -1px;
    top: -1px;
    overflow-x: hidden;
    transition: all 400ms ease-in-out;
    background-color: ${theme.WHITE_COLOR};
    padding-top: 60px;
  }
`;

const NavigationContainer = styled.div<InvertProp>`
  /* margin-top: 100px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.WHITE_COLOR};
`;

const SpecialOffer = styled.div`
  background-color: ${theme.BLACK_COLOR};
  color: ${theme.WHITE_COLOR};
  padding: 24px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-family: ${theme.SECONDARY_FONT};
`;

export { NavBar };
