import React from "react";
import styled, { css } from "styled-components";
import { mobileSize } from "../../util/variables";
import RLoading from "react-spinkit";
import theme from "../../util/theme";

interface Props {
  margin?: string;
  background?: string;
  color?: string;
  width?: number | string;
  dark?: boolean;
}

export const AppButton = styled.button<Props>`
  width: ${({ width }) => (width ? (typeof width === "string" ? width : `${width}px`) : "343px")};
  height: 52px;
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  padding: 1rem;
  border-radius: 32px;
  outline-color: ${theme.FOCUS_OUTLINE_COLOR};
  box-sizing: border-box;

  ${props =>
    props.dark &&
    css`
      border: 2px solid ${theme.WHITE_COLOR};
    `}

  background: ${({ background, disabled, dark }) =>
    disabled
      ? "#d7d7d7"
      : background
      ? background
      : dark
      ? theme.BLACK_COLOR
      : theme.PRIMARY_COLOR};
  color: ${({ color, disabled }) => (disabled ? "#524D57" : color ?? "#fefefe")};
  font-family: ${theme.SECONDARY_FONT};
  font-size: 17px;

  cursor: pointer;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  @media ${mobileSize} {
    width: 100%;
  }
`;

interface LoadingProps extends Props {
  type: "button" | "submit" | "reset";
  disabled: boolean;
  loading: boolean;
  onClick?: any;
  style?: object;
}

export const AppLoadingButton: React.FC<LoadingProps> = ({
  type,
  disabled,
  loading,
  onClick,
  style,
  children,
}) => {
  return (
    <LoadingButton
      type={type}
      disabled={disabled || loading}
      loading={loading}
      style={style}
      onClick={onClick}
    >
      {loading ? (
        <>
          <RLoading name="circle" color="#524d57" fadeIn="none" />
          Loading
        </>
      ) : (
        children
      )}
    </LoadingButton>
  );
};

const LoadingButton = styled.button<LoadingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 343px;
  height: 52px;
  max-width: 100%;
  padding: 16px;
  /* margin: 16px auto; */
  box-sizing: border-box;
  border-radius: 32px;
  outline-color: ${theme.FOCUS_OUTLINE_COLOR};
  font-family: ${theme.SECONDARY_FONT};
  font-size: 17px;
  line-height: 20px;
  background: ${props => (props.disabled ? "#E3E4E5" : theme.PRIMARY_COLOR)};
  color: ${props => (props.disabled ? "#524D57" : "#fefefe")};
  cursor: ${props => (props.disabled ? "default" : "pointer")};

  @media ${mobileSize} {
    width: 100%;
    max-width: 100%;
  }
`;
